.fr-text-default--success {
  color: var(--text-default-success) !important;
}

.fr-text-default--error {
  color: var(--text-default-error) !important;
}

.ul-callout {
  margin: 8px 0 0 12px;
}

.text-of-instructions {
  margin-left: 8px;
  font-size: 18px;
  font-weight: 700;
}

.callout-instructions {
  margin-top: 16px;
}

.instructions-content {
  margin-bottom: 16px;
}

.callout-how-upload {
  padding-inline-start: 0;

  & > li {
    margin: 16px 0 0 27px;
  }
}

.img-callout-margin {
  margin-top: 8px;
  margin-left: 10px;
}

.title-callout-margin {
  margin-top: 16px;
}
