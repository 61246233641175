@import 'stylesheet.scss';

.bold {
  font-weight: 700;
}

.bloc-x-spacing {
  width: 108px;
}

.autocomplete-container {
  position: relative;
  width: 100%;
}
