@import 'stylesheet.scss';

.location {
  .fr-grid-row--gutters > [class^='fr-col-'] {
    width: 100%;
  }

  .location-form {
    gap: 24px;
  }
}

@media (min-width: $breakpoint-md) {
  .location {
    .fr-grid-row--gutters > [class^='fr-col-'] {
      width: unset;
    }
  }
}
