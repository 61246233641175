@import '../../../stylesheet.scss';

.bold {
  font-weight: 700;
}

.mobile-large-button {
  width: 100%;
  justify-content: center;
}

.required-infos {
  font-size: 12px;
  color: #666666;
}

.sticky-container {
  background-color: #fff;
  position: sticky;
  bottom: 0;
  z-index: 1;

  .go-top-container {
    .go-top-link {
      min-width: 125px;
    }

    @media (max-width: 440px) {
      justify-content: center;
    }
  }

  .save-button {
    min-width: 250px;
    @media (min-width: 770px) {
      min-width: 270px !important;
      max-width: 270px !important;
    }

    @media (min-width: 996px) {
      max-height: 40px !important;
    }
  }
}
