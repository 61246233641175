.progress-bar {
  display: flex;
  gap: 0.4rem;

  .progress-bar__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 40px;
    width: 70%;
    margin-top: 0.4rem;

    .progress-bar__sub-container {
      background-color: var(--background-disabled-grey);
      border-radius: 40px;
      height: 8px;
      width: 100%;
      margin-right: 8px;
      position: relative;

      .progress-bar__progress {
        background-color: var(--background-flat-blue-france);
        animation-play-state: paused;
        animation: progress-bar-stripes 2s linear infinite;
        transition: width 0.25s ease, background-color 0.25s ease;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
        height: 100%;
        line-height: 38px;
        background-size: 40px 40px;
      }
    }
  }

  .progress-bar__percentage {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--text-title-blue-france);
    //margin-left: 0.4rem;
  }
}
