#documents {
  .img-aucun-documents {
    max-width: 418px;
  }

  .message-aucun-documents {
    font-weight: 700;
    font-size: 20px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .lds-ring {
      height: 40px;
      width: 40px;

      div {
        width: 30px;
        height: 30px;
        margin: 4px;
        border: 4px solid;
        border-color: var(--text-action-high-blue-france) transparent transparent transparent;
      }
    }
  }
}


