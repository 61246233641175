@import './stylesheet';

#mon-allocation {
  .atestation-link {
    border-bottom: #000091 1px solid;
    color: #000091;
  }

  .download-infos {
    color: rgb(102, 102, 102);
    font-size: 12px;
    --text-spacing: 0;
    --title-spacing: 0;
    position: relative;
    padding-bottom: 1.5rem;
    margin: 0 0 1rem 0;
  }

  /* HTML: <div class="loader"></div> */
  .loader {
    width: 15px;
    height: 15px;
    margin-top: 9px;
    margin-left: 7px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid;
    border-color: #000 #0000;
    animation: l1 1s infinite;
  }
  @keyframes l1 {
    to {
      transform: rotate(0.5turn);
    }
  }

  .row-illustration {
    justify-content: center;
  }

  .payments-card {
    background: #000091;
    color: #fff;
    padding: 48px 0;
    text-align: center;
    min-width: 344px;

    .fr-grid-row {
      margin: 40px;
    }

    .payments-card__title {
      color: #fff;
      margin-bottom: 20px;
      margin-top: 15px;
      font-size: 40px;
      white-space: nowrap;
    }

    .payments-card__left-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .payments-card__right-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-left: 3rem;

      .payments-card__line {
        margin-bottom: 12px;
      }
    }

    hr {
      width: 1px;
      height: auto;
      background: #fff;
    }

    .card-link {
      margin: auto;
      margin-top: 50px;
      color: #fff;
    }
  }

  @media (max-width: $breakpoint-lg) {
    .payment-card__content {
      align-items: center;
    }

    .payments-card {
      background: #000091;
      color: #fff;
      padding: 24px 16px;
      text-align: center;
      min-width: 344px;
      display: flex;
      justify-content: center;

      .fr-grid-row {
        margin: 0;
        flex-direction: column;
      }

      .payments-card__title {
        color: #fff;
        margin-bottom: 20px;
        font-size: 32px;
      }

      .payments-card__left-part {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 24px;
        width: 175px;
      }

      .payments-card__right-part {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
        padding-top: 24px;
        padding-left: 0;
        width: 175px;

        // .payments-card__line {
        //   margin-bottom: 12px;
        // }
      }

      hr {
        width: 100%;
        height: 1px;
        background: #fff;
        width: 160px;
        align-self: center;
      }

      .card-link {
        margin-top: 24px;
        color: #fff;
        width: 175px;
        text-align: left;
      }

      a {
        display: flex;
      }

      [class*=' fr-icon-']::after {
        height: auto;
      }
    }
  }

  .bolder {
    font-weight: bold;
  }

  .column-icon {
    margin-right: 24px;
    &::before {
      width: 48px;
      height: 48px;
      color: #000091;
    }
  }

  .payment-column {
    border: 1px solid #dddddd;
  }
}
