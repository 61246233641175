@import './stylesheet';

#espace-asile {
  .top-row {
    flex-wrap: wrap-reverse;

    @media (min-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }

  .no-reminder-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-height: 123px;
      max-width: 168px;
    }
  }

  .no-payment-card {
    padding: 48px 0;
    text-align: center;
    min-width: 344px;
  }

  .row-illustration {
    justify-content: center;
  }

  .hebergement-link {
    max-height: 24px;
    align-self: center;
  }

  .documents-column {
    min-width: 362px;
  }

  .hebergement-column {
    min-width: 361px;
  }

  .bolder {
    font-weight: bold;
  }

  .payments-card {
    background: #000091;
    color: #fff;
    padding: 48px 0;
    text-align: center;
    min-width: 344px;

    .fr-grid-row {
      margin: 40px;
    }

    .payments-card__title {
      color: #fff;
      margin-bottom: 20px;
      margin-top: 15px;
      font-size: 40px;
      white-space: nowrap;
    }

    .payments-card__left-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .payments-card__right-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-left: 3rem;

      .payments-card__line {
        margin-bottom: 12px;
      }
    }

    hr {
      width: 1px;
      height: auto;
      background: #fff;
    }

    .card-link {
      margin: auto;
      margin-top: 50px;
      color: #fff;
    }
  }

  @media (max-width: $breakpoint-lg) {
    .payments-card {
      background: #000091;
      color: #fff;
      padding: 24px 16px;
      text-align: center;
      min-width: 344px;
      display: flex;
      justify-content: center;
  
      .fr-grid-row {
        margin: 0;
        flex-direction: column;
      }
  
      .payments-card__title {
        color: #fff;
        margin-bottom: 20px;
        font-size: 32px;
      }
  
      .payments-card__left-part {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 24px;
        width: 175px;
      }
  
      .payments-card__right-part {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
        padding-top: 24px;
        padding-left: 0;
        width: 175px;
  
        // .payments-card__line {
        //   margin-bottom: 12px;
        // }
      }
  
      hr {
        width: 100%;
        height: 1px;
        background: #fff;
        width: 160px;
        align-self: center;
      }
  
      .card-link {
        margin-top: 24px;
        color: #fff;
        width: 175px;
        text-align: left;
      }

      a {
        display: flex;
      }

      [class*=" fr-icon-"]::after {
        height: auto;
      }
    }
  }

  .rappel-alert {
    min-width: 327px;
  }

  .fr-card--download {
    max-height: 200px;
    margin-bottom: 24px;
  }

  .fr-col {
    height: fit-content;
  }

  .part-title {
    font-weight: 700;
  }

  .column-icon {
    margin-right: 24px;
    &::before {
      width: 48px;
      height: 48px;
      color: #000091;
    }
  }

  .hebergement-container {
    border: 1px solid #dddddd;
  }

  .title-line {
    justify-content: space-between;
  }
}
