#resources-form {
  h2 {
    margin-bottom: 32px;
  }

  .delete-resources-button {
    flex-direction: column-reverse;
  }

  .upload-files {
    width: 100%;
  }

  .fr-radio-group {
    .fr-label {
      padding-bottom: 0;
    }
  }

  .radio-group-container {
    &.bold {
      font-weight: normal;
    }
    &.aah-group {
      .custom-btn-icon-padding {
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .fr-fieldset--valid {
    background-position: 0 0;
  }
}
