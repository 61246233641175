.simplecard > .fr-card {
  height: auto;
}

.simplecard .fr-card__content {
  padding-top: 0px;
  text-align: center;
}

.simplecard {
  max-width: 580px;
}

.procedure-tile .fr-grid-row .fr-tile {
  height: auto;
}

.procedure-tile .fr-tile__title {
  color: var(--light-text-title-blue-france, #000091);
  font-family: Marianne;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.procedure-tile .fr-highlight {
  padding-left: 1.25rem;
  margin-left: 0;
}

.procedure-tile .description-asile {
  color: var(--neutres-light-grey-01, #161616);
  font-family: Marianne;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.procedure-tile .asile-procedure-desc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.procedure-tile .date-asile {
  color: var(--neutres-light-grey-01, #161616);
  font-family: Marianne;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.simplecard .ratio-25 .fr-card__img img {
  aspect-ratio: 2.5;
}

.numero-dossier-highlight {
  color: var(--fonctionnelles-light-info-02, #0078F3);
}

.numero-dossier-highlight-number {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.numero-dossier-highlight-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}