.custom-modal {
  max-width: 30rem;
  width: 100%;
  border: 0;
  position: relative;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  font-weight: normal;
  &::backdrop {
    background: hsl(0 0% 0% / 50%);
  }
  .fr-modal__body {
    background-color: transparent;
    .fr-modal__header {
      padding: 0 0 1rem 0;
    }
    .fr-modal__content {
      padding: 0;
    }
  }
}
