@import './stylesheet';

.fr-header__brand:focus-within {
  outline: 2px solid #0a76f6;
}

.fr-required-indicator {
  color: red;
}

.fr-valid-text {
  display: none;
}

.fr-infobulle__container {
  display: contents;
}

.fr-content-media__img img {
  width: -webkit-fill-available;
}

.ofii_banner {
  background: $color-grey;
  margin-bottom: 20px;
}

.fr-tile__body {
  justify-content: unset;
}

.card__container {
  width: -webkit-fill-available;

  .fr-card {
    width: -webkit-fill-available;
  }
}

.card-container {
  &__desc {
    justify-content: space-between;
  }
}

.callout-alert {
  background-color: var(--background-contrast-grey) !important;
  box-shadow: inset 0.25rem 0 0 0 var(--background-flat-error) !important;

  &:before {
    background-color: var(--background-flat-grey) !important;
  }
}

.horizontal-card {
  .integration__container {
    margin-bottom: 16px;
  }

  .fr-card {
    margin: 0;
  }
}

.news {
  &.card-container__desc {
    justify-content: flex-end;
  }
}

.fr-header__service {
  order: 2;
  box-shadow: none;
}

.fr-header__operator {
  img {
    height: 54px;
  }
}

.fr-translate .fr-translate__btn {
  margin-top: 0.2rem;
}

.fr-tile__title a {
  font-size: 16px;
}

.fr-badge {
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
}

.fr-callout {
  background: var(--light-background-contrast-grey, #eee);
  box-shadow: inset 4px 0 0 0 var(--light-border-default-blue-france, #6a6af4);
  gap: 12px;

  &::before {
    background-color: var(--light-text-default-grey, #3a3a3a);
  }

  .fr-card__title {
    color: var(--light-text-title-grey, #161616);
    font-family: Marianne;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .fr-callout__text {
    color: var(--light-text-default-grey, #3a3a3a);
    font-family: Marianne;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .fr-btn {
    margin-top: 16px;
  }
}

.fr-alert {
  font-size: 16px;

  .fr-alert__title {
    font-style: normal;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 4px;
  }

  .custom-a-on-alert {
    display: block;
    width: fit-content;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &:a {
    font-size: 16px;
  }
}

.services {
  .fr-card__img {
    padding: 10px 20px;
  }
}

.link-disabled {
  pointer-events: none;
}

.portail {
  img {
    height: 100px;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
}

.news {
  &-title-line {
    max-height: 84px;
    align-items: center;
    justify-content: space-between;

    .fr-link {
      max-height: 24px;
      justify-self: center;
    }
  }
}

.upload-files {
  .fr-hint-text {
    p {
      font-size: 0.75rem;
    }
  }

  .fr-grid-row {
    .fr-icon-question-line {
      height: 24px;
    }
  }
}

.fr-highlight--hipe {
  background-image: linear-gradient(0deg, var(--border-plain-info), var(--border-plain-info));

  span {
    color: var(--border-plain-info);
    font-weight: 700;
  }
}

.fr-consent-banner {
  border: 1px solid var(--light-border-default-grey, #ddd);
  background: var(--light-background-elevation-alt-overlap-grey, #f6f6f6);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 18, 0.16);
  &:focus {
    outline: 2px solid #0a76f6;
    outline-offset: 2px;
  }
}

#toaster {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  overflow: hidden;
  position: sticky;
  width: 100%;
  z-index: 950;

  .toast-valid {
    align-self: center;
    background-color: #dffee6;
    display: flex;
    height: auto;
    margin: 0;
    margin-bottom: 5px;
    opacity: 1;
    position: relative;
    top: 0;
    width: inherit;
  }

  .toast-invalid {
    align-self: center;
    background-color: #ffe8e5;
    display: flex;
    height: auto;
    margin: 0;
    margin-bottom: 5px;
    opacity: 1;
    position: relative;
    top: 0;
    width: inherit;
  }

  .slide-in {
    animation: 1s slideintoast;
  }

  .slide-out {
    animation: 1s slideouttoast;
  }

  .toast-content-valid {
    align-self: center;
    background-color: #dffee6;
    display: flex;
    min-height: 56px;
    height: auto;
    margin: auto;
    margin-bottom: 5px;
    opacity: 1;
    position: relative;
    top: 0;
    width: inherit;
    max-width: 78rem;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #18753c;
    gap: 16px;
  }

  .toast-content-invalid {
    align-self: center;
    background-color: #ffe8e5;
    display: flex;
    min-height: 56px;
    height: auto;
    margin: auto;
    margin-bottom: 5px;
    opacity: 1;
    position: relative;
    top: 0;
    width: inherit;
    max-width: 78rem;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #b34000;
    gap: 16px;
  }

  .toast-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .icon-left-toaster {
    margin-right: 10px;
    align-self: center;

    &::before {
      color: #18753c;
    }
  }

  .icon-close-toaster-valid {
    &::before {
      color: #18753c;
    }
  }

  .icon-close-toaster-invalid {
    &::before {
      color: #b34000;
    }
  }

  @keyframes slideintoast {
    from {
      height: 0;
      margin-bottom: 0;
      min-height: 0;
      top: -56px;
      opacity: 0;
    }

    to {
      height: auto;
      margin-bottom: 5px;
      min-height: 56px;
      top: 0;
      opacity: 1;
    }
  }

  @keyframes slideouttoast {
    from {
      height: auto;
      margin-bottom: 5px;
      min-height: 56px;
      top: 0px;
      opacity: 1;
    }

    to {
      height: 0;
      margin-bottom: 0;
      min-height: 0;
      top: -56px;
      opacity: 0;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  .card-container__desc .fr-link {
    max-height: 1.5rem;
  }

  .card {
    .description-line {
      max-height: 24px;
    }
  }

  .news {
    &-title-line {
      max-height: 35px;
      align-items: center;
      justify-content: space-between;

      .fr-link {
        max-height: 24px;
        justify-self: center;
      }
    }
  }

  .horizontal-card {
    &__container {
      margin-bottom: 0;
    }

    .card-0 {
      margin-right: 12px;
    }

    .card-1 {
      margin-left: 12px;
    }
  }
}

#demande-submitted {
  .fr-modal__content {
    margin-bottom: 16px;

    .mon-avis {
      text-align: center;
      display: block;
      background: none;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: $breakpoint-md) {
  .hide-on-desktop {
    display: none;
  }
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.accueil-integration-accordion {
  .fr-accordion {
    .li-with-img {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 16px;
      flex-wrap: nowrap;
    }

    .icon-in-list {
      height: 32px;
      width: 32px;
      max-width: 32px;
      object-fit: contain;
    }
  }
}

.without-list-style {
  padding-inline-start: 0;
}

.hide-legend legend {
  display: none;
}

.fr-fieldset--valid {
  background-position: 0 -2rem;
}

.fr-header__tools {
  .connexion-button-desktop {
    margin-top: 3px;
  }
}

.fr-header__menu-links {
  .connexion-button-mobile {
    margin-left: 0;
    padding-left: 8px !important;
  }
}

.error-file-field {
  display: none;
}

.file-specific-error {
  position: relative;

  .upload-files {
    .fr-label:first-child {
      color: var(--text-default-error);
    }
  }

  .error-file-field {
    display: block;
  }
}

.file-specific-error::before {
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: -0.75rem;
  bottom: 0;
  left: -0.75rem;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 0.125rem 100%;
  background-image: linear-gradient(0deg, var(--border-plain-error), var(--border-plain-error));
}

.moz-compatibility {
  max-width: -moz-available;
}

.style-stop-propagation {
  font-weight: initial;
}
