@import '../../stylesheet.scss';

.demande-rf {
  .fr-icon-alert-line::before {
    color: #ce0500;
  }

  .fr-icon-checkbox-circle-line::before {
    color: #18753c;
  }
}

.hidden {
  display: none;
}

hr {
  padding-bottom: 1px;
}

.fr-fieldset {
  margin-bottom: 0px;
}

.fr-container--fluid {
  overflow: visible;
}

.custom-btn-icon-padding {
  padding: 0;
  min-height: 0;
}

.flex-element {
  display: flex;
  align-items: center;
}
.flex-element-4 {
  @extend .flex-element;
  gap: 4px;
}

.fr-modal__content {
  .infobulle-img {
    margin: 16px 0 16px 0;
  }

  p {
    margin: 0;
  }
}

.fr-input-group:not(:last-child) {
  margin-bottom: 0;
}

.commitments-form-container {
  display: flex;
  flex-direction: column;

  .cust-mb--4v {
    margin-bottom: -1rem;
  }
}
