@import 'stylesheet';

.bloc-demande-rf {
  max-width: 580px;

  .fr-card__end {
    flex-direction: row;
    justify-content: flex-start !important;
    > div {
      border-bottom: 1px solid currentColor;
      color: var(--light-text-action-high-blue-france, #000091);
      font-size: 14px;      &:hover {
      border-bottom-color: var(--light-text-action-high-blue-france-hover, #000091);
    }
      .fr-icon-arrow-right-line {
        &:before {
          vertical-align: calc((0.55em - var(--icon-size))* 0.5);
          --icon-size: 1rem;
        }
        &:hover {
          border-bottom-color: var(--light-text-action-high-blue-france-hover, #000091);
        }
      }
    }
  }

  .fr-card__title a::after {
    content: none;
  }
}

@media (min-width: $breakpoint-md) {
  .bloc-demande-rf {
    max-width: 588px;
  }
}


