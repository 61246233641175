@import 'stylesheet.scss';

.maintenance-container {
  display: flex;
  width: 100vh;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintenance {
  background: var(--light-background-alt-blue-france, #f5f5fe);
}

.marianne-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
