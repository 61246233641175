@import '../../stylesheet.scss';

.procedures {
  .fr-card {
    max-width: 100%;

    &__img {
      padding: 10px;
    }
  }
}

@media (min-width: $breakpoint-md) {
  .procedures {
    .fr-card {
      max-width: 100%;
    }
  }
}
