.uploader {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .uploader-container {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .focused {
    outline: 2px solid #0a76f6;
  }

  .custom-label {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px 8px;
    min-width: 132px;
    height: 2rem;
    background: var(--background-contrast-grey);
    margin: 0 0.5rem 0 0;
    border-radius: 4px;
    font-size: 14px;

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-contrast-grey);
      }
    }
  }

  .upload-file-input > .fr-upload {
    opacity: 0;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    position: absolute;

    &:focus + label {
      outline: var(--background-contrast-grey) solid 2px;
      outline-offset: 2px;
    }
  }

  .upload-file-name > .fr-hint-text {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 0.5rem 0 0;
    font-size: 14px;
    text-overflow: ellipsis;
    color: #3a3a3a;
  }

  .bin-button {
    scale: 0.75;
  }
}
