.fr-modal.family-modal {
  .fr-modal__title {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .fr-modal__content {
    margin-bottom: 0;

    form {
      margin-bottom: 4rem;
    }

    .fr-modal__footer_no_button {
      position: sticky;
      bottom: 0;
      background: white;
    }
  }

  .required-infos {
    p {
      font-size: 12px;
      color: var(--text-disabled-grey);
    }
  }
}

