.cms-page {
  h1 {
    text-align: center;
  }

  img:not(.content-img) {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  img {
    max-width: 100%;
  }

  .description {
    text-align: center;
  }

  &.news {
    @media (min-width: 576px) {
      #cms-content {
        padding: 40px 80px;
      }
    }
  }

  .align-left {
    text-align: left;
  }
}

.custom-tab-style {
  border: 1px solid #929292;
  padding: 8px;
}

.noa-request-modal {
  z-index: 800 !important;
}

.cookie-page-table {
  margin: 40px 0;
  background: var(--light-background-default-grey, #FFF);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 18, 0.16);
  border-spacing: 0;
  width: 1200px;

  th {
    text-align: center;
    border-bottom: solid 2px #3A3A3A;
    padding: 16px;
  }

  td {
    text-align: center;
    vertical-align: top;
    border-bottom: solid 1px #DDD;
    padding: 16px;
  }

  td:nth-child(3) {
    text-align: left;
  }

  p {
    margin: 0;
  }

}