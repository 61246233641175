#account {
  .account-page-section {
    gap: 16px;
    @media (min-width: 728px) {
      gap: 24px;
    }
  }

  .container-fields{
    background-color: var(--background-default-grey);
    border-top: 1px solid var(--border-default-grey);
    border-bottom: 1px solid var(--border-default-grey);
    @media (min-width: 728px) {
      border-top: none;
      border-bottom: none;
      background-color: var(--background-raised-grey);
      --idle: transparent;
      --hover: var(--background-raised-grey-hover);
      --active: var(--background-raised-grey-active);
      filter: drop-shadow(var(--raised-shadow));
    }
  }

  .account-page-input {
    width: 100%;
    max-width: 325px;
    margin: 0;
    input {
      background: #e5e5e5;
    }
  }

  .stranger_number {
    color: #0063cb;
    font-weight: 700;
  }

  .password_change_button {
    height: 40px;
    align-self: flex-end;

    @media (min-width: 728px) {
      margin-left: 8px;
    }

    @media (min-width: 752px) {
      margin-left: 32px;
    }
  }
}
