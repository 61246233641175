@import '../../stylesheet.scss';

#creation-compte {
  .form-bg {
    background: var(--light-background-alt-grey, #f6f6f6);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  @media (max-width: 576px) {
    .form-bg {
      margin: 0 -1rem;
    }
  }

  .separator {
    display: flex;
    height: 1px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    background: var(--grey-900-175, #dddddd);
    margin: 32px 0;
  }

  .fr-stepper {
    margin: 0;
  }

  .controls-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: right;
    align-self: stretch;
    gap: 16px;
  }

  @media (max-width: 576px) {
    .controls-buttons {
      flex-direction: column;
    }
  }

  .prenom-supp-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 16px;
  }

  .btn-bin {
    align-self: flex-end;
  }

  @media (min-width: 576px) {
    .btn-precedent {
      max-width: 131px !important ;
      width: 131px;
    }

    .next-button {
      min-width: 107px !important;
      max-width: fit-content !important;
      max-height: 40px !important;
    }
  }

  .btn-ajout-prenom {
    max-width: 184px;
    width: 184px;
    font-size: 14px;
    justify-content: unset;
  }

  .return-to-login-button {
    max-width: 286px;
    align-self: right;
  }
  
  .fr-modal__content {
    color: #161616;
  }

  .infobulle-identifiant-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .infobulle-identifiant-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }

  .infobulle-identifiant-col > img {
    max-width: 100%;
  }

  .infobulle-identifiant-col {
    max-width: 50%;
  }

  .new-name-with-bin {
    flex-wrap: nowrap;
    gap: 24px;
    flex-direction: row;
  }

  .new-name {
    width: 100%;
  }

  @media (max-width: 576px) {
    .infobulle-identifiant-row {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: center;
    }

    .infobulle-identifiant-col {
      max-width: 100%;
    }
  }

  .label-infobulle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}