.scroll-list {
  background-color: white;
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow-x: hidden;
  z-index: 899;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  & > ul > li {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 45px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: var(--grey-950-100);
      outline: none;
    }
  }

  & > ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0 2px;
    cursor: pointer;
  }

  .selected {
    background-color: var(--grey-950-100);
    outline: 1px solid var(--border-action-high-blue-france);
  }
}

.scroll-list-error {
  top: 73%;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-container:hover .btn-delete, 
.autocomplete-container:hover .btn-delete-error {
  display: block;
}

.btn-delete {
  display: none;
  position: absolute;
  bottom: 11px;
  right: 3px;
  background-color: transparent;
  border: none;
  --hover-tint: transparent;
  --active-tint: transparent;

  &::before {
    font-size: 0.5em;
    width: 15px;
    height: 15px;
  }
}

.btn-delete-error {
  display: none;
  position: absolute;
  bottom: 48px;
  right: 3px;
  background-color: transparent;
  border: none;
  --hover-tint: transparent;
  --active-tint: transparent;

  &::before {
    font-size: 0.5em;
    width: 15px;
    height: 15px;
  }
}

.absolute-state-message > p {
  position: absolute;
}
