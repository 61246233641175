.fr-form {
  .form-action-row {
    flex-wrap: wrap-reverse;
  }

  .actions-column {
    align-content: center;
    min-width: 250px;
    flex: none;
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
      flex: 1;
    }
  }

  .next-button {
    // min-width: 250px;
    @media (min-width: 768px) {
      max-height: 40px !important;
      min-width: 107px !important;
      max-width: fit-content !important;
    }
  }

  #commitments-form {
    .next-button {
      @media (min-width: 770px) {
        min-width: 225px !important;
        max-width: fit-content !important;
      }
    }
  }

  .back-link-container {
    @media (max-width: 767px) {
      justify-content: center;
    }

    .back-link {
      width: fit-content;

      @media (min-width: 996px) {
        //min-width: 160px;
      }
    }
  }

  @media (min-width: 996px) {
    input:focus,
    input[type='checkbox']:focus,
    button:focus {
      outline-style: auto !important;
    }

    .uploader {
      .custom-label:focus {
        outline-style: groove;
      }
    }
  }
}
