@import '../../stylesheet.scss';

.fr-content-media__img img {
  width: -webkit-fill-available;
}

.ofii_banner {
  background: $color-grey;
  margin-bottom: 20px;
}

.fr-tile__body {
  justify-content: unset;
}

.card__container {
  width: -webkit-fill-available;
  .fr-card {
    width: -webkit-fill-available;
  }
}

.card-container {
  &__desc {
    justify-content: space-between;
  }
}

.horizontal-card {
  .integration__container {
    margin-bottom: 16px;
  }

  .fr-card {
    margin: 0;
  }
}

.news {
  &.card-container__desc {
    justify-content: flex-end;
  }
}

@media (min-width: $breakpoint-lg) {
  .card-container__desc .fr-link {
    max-height: 1.5rem;
  }

  .horizontal-card {
    &__container {
      margin-bottom: 0;
    }

    .card-0 {
      margin-right: 12px;
    }

    .card-1 {
      margin-left: 12px;
    }
  }

  .news {
    .fr-card {
      margin-bottom: 0;

      &--first {
        margin-left: 0;
      }

      &--last {
        margin-right: 0;
      }
    }
  }

  .part-desc {
    max-width: 570px;
  }
}

.home-tile {
  .home-service-tile {
    h3, h3 > a:not([href]) {
      order: 2;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 700;
      margin: var(--title-spacing);
      margin-bottom: 16px;
      color: var(--text-title-grey);
      cursor: initial;
    }
  }

  .tile-img-title {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: $breakpoint-lg) {
  .integration__container {
    .align-img-top {
      .fr-card__img img {
        object-position: 50% 21%;
      }
    }
  }
}

