@import 'stylesheet';

.icon-position {
  &:before {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media (max-width: $breakpoint-md) {
  .fr-fieldset__content {
    flex-direction: column;
  }
}

#family-form {
  h2 {
    font-size: 1.5rem;
  }
  dialog {
    .fr-modal__content p {
      font-size: 1rem !important;
      font-weight: 400 !important;
    }
  }
  p {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .items-container {
    display: flex;
    gap: 24px;
  }

  .fr-card__end {
    font-size: 16px;
  }

  .fr-alert.fr-alert--warning p {
    font-size: 16px;
  }
  .fr-alert.fr-alert--info p {
    font-size: 16px;
  }
}

.union-date {
  display: flex;
  flex-direction: column;
  .fr-input {
    width: 100px;
  }
  @media (min-width: $breakpoint-sm) {
    flex-direction: row;
    gap: 24px;
    .fr-input {
      width: 148px;
    }
  }
}

.union-date > div {
  display: block;
  width: 132px;
  flex: none;
  @media (min-width: $breakpoint-sm) {
    width: 180px;
  }
}

.card-title {
  height: auto !important;

  h3 {
    color: var(--background-flat-blue-france);
  }

  .fr-card__start {
    margin-bottom: 1rem !important;
  }

  .fr-badge {
    font-weight: 700;
  }
}

.beneficiaire-adresse {
  text-align: left;
  cursor: pointer;

  h3 {
    color: var(--background-flat-blue-france);
  }

  .fr-tile__body {
    .fr-tile__title:before {
      background-position: 0 100%;
      background-size: 100% 0.25rem;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        0deg,
        var(--border-active-blue-france),
        var(--border-active-blue-france)
      );
    }

    .fr-tile__desc {
      padding-bottom: 0.8rem;
    }

    .fr-tile__footer {
      display: flex;
      flex-direction: row-reverse;

      button {
        color: var(--background-flat-blue-france);
      }
    }
  }
}

.address-radio {
  .fr-fieldset__content {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    .fr-radio-group {
      width: 45%;
    }
  }
}

.modal-content {
  .fr-modal__footer {
    ul {
      justify-content: center !important;
    }
    li > button {
      padding: 0 5rem !important;
    }
  }
}

.custom-modal {
  .body {
    font-size: 25px;
    color: black;
    background: #f5f5f5;
  }
  .header-content {
    border: 1px solid black;
    justify-content: space-between;
  }
  .header-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    cursor: pointer;
  }
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    border: 1px solid #fff;
    width: 500px;
    background-color: #fff;
    border-radius: 15px;
  }
  .exit-icon {
    cursor: pointer;
    font-size: 20px;
  }
  .modal-title {
    color: #6821c3;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
  }
  .modal-image img {
    width: 8rem;
  }
  .modalText {
    text-align: justify;
  }
  .modal-button button {
    border: 1px solid #6821c3;
    padding: 6px;
    width: 8rem;
    background-color: #6821c3;
    color: white;
    border-radius: 7px;
  }
}
