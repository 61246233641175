#contact {
  .container-success {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;

  }

  .textarea-counter{
    font-size: 12px;
    color: var(--text-mention-grey);
    font-weight: 400;
    text-align: right;
  }

}

