@import '../../stylesheet';


#espace-rf {

  .fr-callout--orange-terre-battue {
    background-color: #FFE8E5;
    --idle: transparent;
    --hover: #FFE8E5;
    --active: #FFE8E5;
    box-shadow: inset 0.25rem 0 0 0 var(--border-plain-warning);
    &:before {
      background-color: var(--border-plain-warning);
    }

    p {
      font-size: 18px;
    }
  }

  .fr-badge{
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .bold{
    font-weight: 700;
  }

  .recap-demande {
    h3 {
      margin: 0;
    }

    .container {
      border: 1px solid var(--border-default-grey);
      padding: 0 40px;

      .separateur {
        border-bottom: 1px solid var(--border-default-grey);
        padding-bottom: 40px
      }
    }

    .icon {
      text-align: right;
      padding-right: 24px;

      span {
        color: var(--background-flat-blue-france);

        &:before {
          --icon-size: 3rem;
        }
      }
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 700;
  }

  .text {
    font-size: 1rem;
    font-weight: 400;
  }

  .beneficiaire-demande {
    .icon {
      text-align: right;
      padding-right: 24px;

      span {
        color: var(--background-flat-blue-france);

        &:before {
          --icon-size: 1.5rem;
        }
      }
    }
  }

  .documents-title {
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: start;
      gap: 24px;
      margin-bottom: 16px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0
    }

  }

  .documents {
    .fr-card__title {
      a {
        &:after {
          mask-image: url("../../../public/dsfr/icons/system/download-line.svg")
        }
      }
    }

    .fr-link {
      text-align: left;
    }
  }

  .enquete-container {
    display: flex;
    gap: 24px;
    width: 100%;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 8px;
    }
    .enquete {
      display: flex;
      border: 1px solid var(--border-default-grey);
      color: var(--text-title-blue-france);
      font-weight: 700;
      width: 100%;
      i.fr-icon-timer-line{
        margin-top: 0.125rem;
        &:before{
          --icon-size: 1rem;
          margin-left: -0.125rem;
          margin-right: 0.25rem;
        }

      }
    }
    .fr-badge{
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}


